#full-information .Info-container {
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-width: 1000px;
  margin: 50px auto;
  box-shadow: 0 20px 20px 20px var(--grayish);
}

#full-information .Info-container h2 {
  text-align: center;
  padding: 20px;
  text-decoration: underline;
  font-size: 2rem;
}

#full-information .Info-container #content-wrapper {
  display: flex;

  flex-direction: row;
  margin: 20px auto;
  justify-content: center;
}

#full-information .Info-container .right-container img {
  height: 300px;
  width: 300px;
}

@media (max-width: 600px) {
  #full-information .Info-container {
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    max-width: 500px;
    margin: 50px auto;
    padding-bottom: 200px;
    box-shadow: 0 5px 5px 5px var(--grayish);
  }

  #full-information .Info-container .right-container img {
    height: 200px;
    width: 200px;
  }
}
