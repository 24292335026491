#notification .notification-container {
  max-width: var(1100px);
  margin: 50px 200px;
  padding: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 5px var(--light-blue);
}

#notification .notification-container h1 {
  margin: auto;
  font-size: 40px;
  margin-bottom: 5px;
}

#notification .notification-container .underline {
  width: 10px;
  background-color: var(--light-blue);
  padding: 2px 70px;
  margin: auto;
  margin-bottom: 25px;
  border-radius: 20px;
}

#notification .content-wrapper {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

#notification .content-wrapper img {
  height: 150px;
  width: 150px;
}

/* #notification .notification-container .left-content {
  display: flex;
  justify-content: space-between;
} */

#notification .notification-container .left-content .getMedicine {
  color: var(--green-color);
}

#notification .notification-container .line-break:not(:last-child) {
  width: 100%;
  background-color: var(--grayish);
  padding: 1px 600px;
  margin: auto;
  margin-bottom: 20px;
  border-radius: 20px;
}

@media (max-width: 600px) {
  #notification .notification-container {
    width: 350px;
    margin: 30px auto;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 5px var(--light-blue);
  }

  #notification .notification-container h1 {
    margin: auto;
    font-size: 25px;
    margin-bottom: 5px;
  }

  #notification .notification-container .underline {
    width: 10px;
    background-color: var(--light-blue);
    padding: 2px 40px;
    margin: auto;
    margin-bottom: 25px;
    border-radius: 20px;
  }
}
