#diagnosticsUserPortal .equipment-nav-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 10;
  background: var(--light-color);
}

#diagnosticsUserPortal .equipment-nav-container .search-bar {
  display: flex;
  border: none;
}

#diagnosticsUserPortal
  .equipment-nav-container
  .search-container
  .search-list-container {
  position: absolute;
  width: 100%;
  border: var(--light-color) 1px solid;
  background: var(--white-color);
}

#diagnosticsUserPortal .equipment-nav-container .search-bar input {
  border-radius: 5px;
  border: var(--light-color) 1px solid;
  outline: none;
  width: 100%;
  padding-right: 250px;
}
#diagnosticsUserPortal .equipment-nav-container .search-bar li {
  cursor: pointer;
  padding: 5px 1rem;
  color: var(--color-primary);
  border-bottom: var(--light-color) 1px solid;
}

#diagnosticsUserPortal .equipment-nav-container .search-bar li:hover {
  background: var(--light-color);
}
#diagnosticsUserPortal .equipment-nav-container .search-bar input:focus {
  outline: none;
  border-color: var(--color-primary);
}

#diagnosticsUserPortal .medicine-detail img {
  max-width: 300px;
  max-height: 250px;
}

#diagnosticsUserPortal .medicine-detail {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 25px;
  margin-bottom: 50px;
}

#diagnosticsUserPortal .img-slider-section {
  width: 100%;
}
#diagnosticsUserPortal .slider-card {
  width: 100%;
  height: 280px;
  margin: 10px auto;
  position: relative;
  overflow: hidden;
  border: 1px var(--grayish) solid;
  background-color: var(--background-color);
}

#diagnosticsUserPortal .container .img-slider img {
  height: 100%;
  background-color: var(--grayish);
  object-fit: cover;
  z-index: -1;
}
#diagnosticsUserPortal .slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: transform 1s;
}
#diagnosticsUserPortal .content-slide {
  flex-direction: column;
}

#diagnosticsUserPortal .imageSection {
  width: 100%;
  margin: 80px auto; /* Center the slider horizontally */
}

#diagnosticsUserPortal .sectionCenter {
  position: relative; /*Needed for button positioning */
}

#diagnosticsUserPortal .sectionCenter .imagePart1 {
  position: relative;
}

#diagnosticsUserPortal .right-functionalities {
  display: flex;
  gap: 5px;
}

#diagnosticsUserPortal .sectionCenter button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
#diagnosticsUserPortal .sectionCenter .imagePart img {
  height: 300px;
  object-fit: cover;
}

/* StyledButton.css */
#diagnosticsUserPortal .prev,
#diagnosticsUserPortal .next {
  background-color: var(--grayish); /* Semi-transparent background */
  color: var(--light-color);
  padding: 10px;
  border: 50px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 30px;
}

#diagnosticsUserPortal .prev {
  left: 10px; /* Adjust button position */
}

#diagnosticsUserPortal .next {
  right: 10px; /* Adjust button position */
}
@media (max-width: 500px) {
  #diagnosticsUserPortal .equipment-nav-container .search-bar input {
    border-radius: 5px;
    border: var(--light-color) 1px solid;
    outline: none;
    width: 250px;
    padding-right: 0;
    font-size: 10px;
  }

  #diagnosticsUserPortal .equipment-nav-container .search-bar li {
    cursor: pointer;
    padding: 4px 1rem;
    font-size: 10px;
    color: var(--color-primary);
    border-bottom: var(--light-color) 1px solid;
  }
  #diagnosticsUserPortal
    .equipment-nav-container
    .search-container
    .search-list-container
    p {
    font-size: x-small;
    padding: 0 10px;
  }

  #diagnosticsUserPortal #nav-bar #first-nav .container .nav-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }
  #diagnosticsUserPortal .equipment-nav-container .search-container {
    display: flex;
    flex-direction: column;
  }

  #diagnosticsUserPortal #choose_file {
    /* border: 1px solid #ccc; Light border */
    margin-top: 1px;
    margin-right: 5px;
    padding: 2px 2px;
    font-size: 10px;
    cursor: pointer;
  }
  #diagnosticsUserPortal .btn-upload {
    background-color: var(--light-blue);
    padding: 5px;
    border-radius: 10px;
  }
}
