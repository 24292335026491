#doctorList .container {
  max-width: 1000px;
}
#doctorList #find-right-doctor .btn {
  width: fit-content;
  margin: 2px;
  padding: 5px;
  background-color: var(--color-primary);
}

#doctorList .active-sign {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--green-color);
  margin-right: 5px;
}

#doctorList .inactive-sign {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--dark-color);
  margin-right: 5px;
}

.doctor-card {
  /* display: grid;
  grid-template-columns: 1fr 2fr; */
}
.doctor-detail-row {
  /* margin-right: 60px; */
}
@media (max-width: 770px) {
  /* .doctor-card {
    display: grid;
    grid-template-columns: 1fr;
  } */
}
