#pharmacy-portal .medicine-nav-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 10;
  background: var(--light-color);
}

#pharmacy-portal .medicine-nav-container .nav-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

#pharmacy-portal .medicine-nav-container .search-bar {
  display: flex;
  border: none;
}

#pharmacy-portal .medicine-nav-container .search-bar .search-wrapper {
  position: relative;
}

#pharmacy-portal .medicine-nav-container .search-container {
  display: flex;
}
#pharmacy-portal
  .medicine-nav-container
  .search-container
  .search-list-container {
  position: absolute;
  width: 100%;
  border: var(--light-color) 1px solid;
  background: var(--white-color);
}

#pharmacy-portal .medicine-nav-container .search-bar input {
  border-radius: 5px;
  border: var(--light-color) 1px solid;
  outline: none;
  max-width: 500px;
  padding: 0.6rem 1rem;
  padding-right: 300px;
}
#pharmacy-portal .medicine-nav-container .search-bar li {
  cursor: pointer;
  border-bottom: var(--light-color) 1px solid;
  padding: 5px 1rem;
}
#pharmacy-portal .medicine-nav-container .search-bar li:hover {
  background: var(--light-color);
}
#pharmacy-portal .search-list-container .lists .med-list {
  color: var(--color-primary);
}

#pharmacy-portal .medicine-nav-container .search-bar input:focus {
  outline: none;
  border-color: var(--color-primary);
}

#pharmacy-portal .pharmacy-portal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

#pharmacy-portal .pharmacy-portal .pharmacy-title {
  margin-bottom: 30px;
  font-size: 40px;
  text-decoration: underline;
}

#pharmacy-portal .medicine-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
}

#pharmacy-portal .medicine-grid img {
  height: 200px;
  width: 200px;
}

#pharmacy-portal .medicine-card {
  border: 1px solid var(--grayish-1);
  background: var(--light-gray);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
}

#pharmacy-portal .medicine-card h2 {
  font-weight: 10px;
  margin-bottom: 5px;
}

#pharmacy-portal .medicine-card p {
  margin-bottom: 5px;
}

#pharmacy-portal .post-medicine-button {
  background-color: var(--color-primary);
  color: var(--light-color);
  padding: 15px 25px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: auto;
  width: 250px;
  margin-bottom: 20px;
  font-size: large;
  text-align: center;
}

#pharmacy-portal .post-medicine-button:hover {
  background-color: var(--accent-blue-1);
}

#pharmacy-portal .medicine-nav-container .notify {
  margin-right: 40px;
  position: relative;
}

#pharmacy-portal .notify #cart-btn a {
  color: var(--light-color);
  background-color: var(--color-primary-1);
  padding: 8px;
  border-radius: 10px;
}

#pharmacy-portal .medicine-nav-container .notify .count-btn {
  position: absolute;
  top: -15px;
  right: -5px;
  background: rgba(240, 221, 221, 0.9);
  padding: 1px 5px;
  z-index: 2;
  text-align: center;
  border-radius: 50%;
  aspect-ratio: 1/1;
  height: 20px;
  width: 20px;
  font-size: 12px;
}

#pharmacy-portal .medicine-nav-container .notify .count-btn a {
  color: var(--color-secondary);
  font-weight: bold;
}

@media (max-width: 800px) {
  #pharmacy-portal .medicine-nav-container .search-bar input {
    padding-right: 60px;
    font-size: x-small;
  }
  #pharmacy-portal .medicine-nav-container .search-bar li {
    cursor: pointer;
    border-bottom: var(--light-color) 1px solid;
    padding: 5px 1rem;
    font-size: x-small;
  }

  #pharmacy-portal .medicine-nav-container .search-list-container p {
    font-size: x-small;
    padding: 0 10px;
  }
  #pharmacy-portal .medicine-nav-container .notify {
    margin-right: 10px;
    position: relative;
  }
  #pharmacy-portal .notify #cart-btn a {
    color: var(--light-color);
    background-color: var(--color-primary-1);
    padding: 5px;
    border-radius: 10px;
    font-size: 8px;
  }

  #pharmacy-portal .medicine-nav-container .notify .count-btn {
    position: absolute;
    top: -8px;
    right: -1px;
    background: rgba(240, 221, 221, 0.9);
    padding: 1px 5px;
    z-index: 2;
    text-align: center;
    border-radius: 50%;
    aspect-ratio: 1/1;
    height: 15px;
    width: 15px;
    font-size: 10px;
  }

  #pharmacy-portal .pharmacy-portal {
    padding: 30px;
  }

  #pharmacy-portal .pharmacy-portal .pharmacy-title {
    font-size: 25px;
  }

  #pharmacy-portal .medicine-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  #pharmacy-portal .medicine-grid img {
    height: 100px;
    width: 100px;
  }

  #pharmacy-portal .medicine-card p {
    margin-bottom: 0;
  }

  #pharmacy-portal .medicine-card h2 {
    font-size: medium;
  }

  #pharmacy-portal .post-medicine-button {
    width: 200px;
    font-size: small;
    padding: 5px;
    margin-bottom: 10px;
    text-align: center;
  }
}
/* card antd */
#medicine-description {
  font-size: 12px; /* Smaller text size for the description */
  color: #555; /* Lighter color for text */
  margin-bottom: 16px; /* Space between description and footer */
}

#medicine-description p {
  margin: 0; /* Remove default margin */
}

.medicine-antd-card-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Price on the left, button on the right */
  /* align-items: center; */
  /* margin: 16px; */
  /* border: #e3dbdb solid 1px; */
}

.medicine-antd-card-footer .price {
  font-weight: bold;
  margin-top: 9px;
  /* margin-left: 90px; */
  color: #1890ff; /* Primary color for the price */
}
.medicine-antd-card-footer .antd-card-btn {
  padding: 0px 20px;
  border-radius: 4px;
}
.medicine-antd-card-footer .ant-card-meta-title {
  font-size: 16px; /* Title size */
  font-weight: bold;
}

.medicine-antd-card-footer .ant-card-meta-description {
  margin-top: 12px; /* Space between title and description */
}
