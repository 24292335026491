#splash .form-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

#splash .splash {
  min-width: 400px;
  margin: 100px auto;
  background: var(--white-color);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

@media screen and (max-width: 768px) {
  #splash .splash {
    width: 100%;
    height: 100%;
    background-color: var(--white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  #splash .splash h1 {
    color: var(--color-primary);
  }

  #splash .splash div {
    margin: 10px;
  }

  #splash .splash .card-img {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin: 90px 0px 0px;
  }

  #splash .splash .card-img img {
    object-fit: cover;
    width: 120px;
  }
}
