/* Container styling */
#AdminSignUpCategory {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100%;
  max-width: 400px;
  border-radius: 5px;
  padding: 1rem;
  border: 1px solid #ddd;
}

/* Label styling */
#AdminSignUpCategory #sign-up-category-container label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

/* Select dropdown styling */
#AdminSignUpCategory #sign-up-category-container select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 20px;
  transition: border-color 0.3s ease;
}

#AdminSignUpCategory #sign-up-category-container select:focus {
  border-color: #007bff;
  outline: none;
}

/* Optional: renderForm container styling */

#AdminSignUpCategory .radio-group-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#AdminSignUpCategory .radio-group-container .radiogroup {
  padding-bottom: 1rem;
}

#AdminSignUpCategory .form-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#AdminSignUpCategory h2 {
  text-align: center;
  margin-bottom: 2rem;
}
#AdminSignUpCategory .sign-up-form {
  margin: auto;
  margin-top: 1rem;
  width: 100%;
}
#AdminSignUpCategory .form-group {
  margin-bottom: 1rem;
}

#AdminSignUpCategory .sign-up-form label {
  display: block;
  margin-bottom: 5px;
}

#AdminSignUpCategory .sign-up-form Select,
#AdminSignUpCategory .sign-up-form Input {
  width: 100%;
}

#AdminSignUpCategory .sign-up-form button {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
}

#AdminSignUpCategory .sign-up-form button:hover {
  background-color: var(--accent-blue-3);
}

/* form for patient */
.patient-container {
  background-color: var(--pale-blue-grey);
}

@media (max-width: 768px) {
}

@media (max-width: 480px) {
  #AdminSignUpCategory {
    width: 100%;
    box-shadow: none;
  }
}
