.general-information {
  text-align: center;
}
.antd-card {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.general-information img {
  max-width: 200px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.general-information p {
  margin: 5px 0;
}

.departments {
  margin: 20px 0;
}

.departments ul {
  list-style: none;
  padding: 0;
}

.departments li {
  background: #f4f4f4;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
}

.doctors {
  margin: 20px 0;
}

.doctors ul {
  list-style: none;
  padding: 0;
}

.doctors li {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.doctors img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.reviews {
  margin: 20px 0;
}

.review {
  background: #f9f9f9;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}

.settings {
  margin: 20px 0;
}

.settings label {
  display: block;
  margin: 10px 0;
}
