#patientGeneralForm .patient-form-container {
  width: 100%;
  margin: 2rem auto;
}
#patientGeneralForm .patient-form-container .form-wrapper {
  margin: 0px auto;
}
/* #patientGeneralForm .patient-form-container .form-heading div {
  width: 80%;
  margin: auto;
  padding: 1rem 3rem;
  border-radius: 2rem;
  text-align: center;
} */

#patientGeneralForm .patient-form-container .form-heading h2 {
  display: flex;
  color: var(--light-text-color);
  font-weight: 500;
  align-items: center;
  text-align: center;
  margin: auto;
}
#patientGeneralForm .patient-form-container .form-heading {
  display: flex;
  color: var(--light-text-color);
  font-weight: 500;
  align-items: center;
  text-align: center;
  margin: auto;
  padding: 1rem;
}

#patientGeneralForm .patient-form-container .form .radio-wrapper {
  display: flex;
  align-items: center;
}

#patientGeneralForm .patient-form-container .form {
  width: 80%;
  margin: auto;
  box-shadow: var(--shadow-color) var(--shadow-blur);
  border-radius: 1rem;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#patientGeneralForm .patient-form-container .form .patient-form-wrapper > * {
  margin: 0;
  padding: 0;
}
#patientGeneralForm .patient-form-container .form .patient-form-wrapper {
  min-width: 90% !important;
  margin-top: 1rem;
  padding: 1rem;
}

#patientGeneralForm .patient-form-container .form .content {
  padding-top: 1rem;
  display: flex;
  justify-content: start;
}

#patientGeneralForm .patient-form-container .form .content .radio-wrapper {
  margin-right: 20px;
}
#patientGeneralForm
  .patient-form-container
  .form
  .content
  .radio-wrapper
  input[type="radio"] {
  margin-right: 5px;
}
#patientGeneralForm .patient-form-container .form input[type="text"] {
  padding: 7px;
  width: 100%;
  outline: none;
  border: 1px solid var(--shadow-color);
  border-radius: 5px;
}

#patientGeneralForm .patient-form-container .form .form-label {
  width: 100%;
  background: var(--color-primary);
  border-radius: 1rem 1rem 0 0;
  padding: 1rem 2.5rem;
}

#patientGeneralForm .patient-form-container .form .form-label h3 {
  color: var(--light-color);
}

#patientGeneralForm .patient-form-container .form .btn {
  background: var(--accent-blue-2);
  color: var(--white-color);
  padding: 8px 15px;
  border-radius: 5px;
  width: 90%;
  align-items: center;
  border: none;
  margin: 5px;
  text-align: center;
}

#patientGeneralForm .patient-form-container .form button:hover {
  background-color: var(--light-blue);
}
#patientGeneralForm .selected-symptoms-btn-container .right-btn-container {
  display: flex;
  justify-content: space-between;
}
