/* service */

#home-service .card-img {
  overflow: hidden;
  height: 150px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  min-width: 180px;
}

#home-service .card-img img {
  height: 100%;
  object-fit: cover;
  object-position: center top;
}

#home-service .card:nth-child(even) .card-img {
  background-color: var(--icon-bg);
}
#home-service .card:nth-child(odd) .card-img {
  background-color: var(--accent-blue-1);
}
#home-service .card {
  margin: 2px 20px;
  border-radius: 20px;
  box-shadow: 1px 5px 5px var(--grayish), -1px -1px 1px var(--grayish);

  min-width: 180px;
}
/* book */
#home-book .card-img {
  min-width: 280px;
  height: 200px;
}

#home-book .card-img img {
  height: 100%;
  object-fit: cover;
}
#home-book .card {
  min-width: 280px;
}

/* article */
#home-article .card {
  min-width: 230px;
}
#home-article .card-img {
  min-width: 100%;
}
#home-article .btn {
  width: fit-content;
}
/* download */

#home-download .card .card-img {
  margin: 5px 30px;
}
#home-download .card .card-img img {
  height: 50px;
  border-radius: 10px;
}
#home-download .download-img-1 {
  /* height: 400px; */
  width: 400px;
}
#home-download .download-img-1 img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}
