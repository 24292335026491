#appointment .appointment-item {
  border: 1px solid var(--light-gray-2);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: var(--light-gray);
}

#appointment .appointment-date,
#appointment .appointment-time,
#appointment .appointment-patient {
  margin-bottom: 10px;
}

#appointment .appointment-cancel-btn,
#appointment .appointment-reschedule-btn {
  padding: 10px 20px;
  background-color: var(--accent-blue-2);
  color: var(--background-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

#appointment .appointment-reschedule-btn:hover,
#appointment .appointment-cancel-btn:hover {
  background-color: var(--accent-blue);
}
