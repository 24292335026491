.formListItems {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 10px;
  width: 100%;
}

.formListItems .key-value-pair strong {
  font-weight: 600;
  color: #1890ff;
  margin-right: 8px;
}

.formListItems .key-value-pair span {
  color: #555;
}

.formListItems .key-value-pair .value {
  font-weight: bold;
}

.ListContainer .delete-btn {
  color: #ff4d4f !important;
  font-weight: 500 !important;
}

.ListContainer .delete-btn:hover {
  color: #ff7875 !important;
}

.ListContainer .ant-list-item:hover {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: pointer;
}
