.profile .form-wrapper {
  max-width: 600px;
}
.profile .doctor-profile label {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.profile .btn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profile .btn-wrapper .btn {
  padding: 0.3rem 0.5rem;
  margin: 4px;
  width: 80%;
  border-radius: 10px;
}
