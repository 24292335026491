#child-body {
  position: relative;
  width: 100%;
}

#child-body .child-front-body-container-card {
  top: 20px;
  position: relative;
  height: 600px;
  overflow: hidden;
  margin: auto;
}
#child-body .child-front-hoverable-svg-container {
  position: absolute;
  top: -45px;
  right: -135px;
  width: 646px;
}
#child-body .child-front-body-img {
  width: 320px !important ;
  position: absolute;
  top: 10px;
  left: 0;
}

#child-body .child-back-body-container-card {
  top: 20px;
  position: relative;
  height: 600px;
  overflow: hidden;
}
#child-body .child-back-hoverable-svg-container {
  position: absolute;
  top: 25px;
  left: -111px;
  width: 535px;
}

#child-body .child-back-body-img {
  width: 300px !important ;
  position: absolute;
  top: 25px;
  left: 15px;
}
#child-body .rotation {
  position: absolute;
  top: 150px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
  width: 40px;
}
#child-body .rotation:hover {
  border-radius: 5px;
  /* background: #f3f3f3; */
}
#child-body .hidden {
  display: none;
}
#child-body .hoverable {
  fill: #5899c1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

#child-body .hoverable:hover {
  opacity: 0.7;
}
