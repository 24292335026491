.acc-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: white;
  border-right: 1px solid #e1e4e8;
  padding: 1rem;
  border-radius: 10px 0 0 10px;
}
.acc-sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.acc-sidebar-header .header-content {
  display: flex;
  align-items: center;
  padding: 0.2rem 0.7rem;
  border-bottom: 1px solid #e1e4e8;
}

.acc-sidebar-header .account-close-btn {
  cursor: pointer;
}

.acc-sidebar-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.acc-sidebar-title {
  font-size: 18px;
  font-weight: bold;
}

.acc-sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.acc-sidebar-menu li {
  padding: 0.2rem 0.7rem;
  cursor: pointer;
}

.acc-sidebar-menu li:hover {
  background-color: #f6f8fa;
}

.acc-sidebar-menu li a,
.acc-sidebar-footer li {
  display: flex;
  align-items: center;
  justify-content: start;
  color: #636c76;
}
.acc-sidebar-menu li a span,
.acc-sidebar-footer li span {
  padding-left: 5px;
}
.acc-sidebar-footer {
  padding: 0.2rem 0.7rem;
  border-top: 1px solid #e1e4e8;
}

.acc-sidebar-footer li {
  padding: 10px 0;
  cursor: pointer;
}

.acc-sidebar-footer li:hover {
  background-color: #f6f8fa;
}
