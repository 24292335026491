#hospital-booking
  :where(.css-dev-only-do-not-override-vryruh).ant-space-gap-col-large {
  column-gap: 0;
}
#hospital-booking .autocomplete-container {
  display: flex;
  justify-content: center;
}
.ant-spin-dot.ant-spin-dot-spin .ant-spin-dot-item {
  color: #1677ff;
}
