/* Navigation */

#header #nav-bar .nav-container {
  display: grid;
  grid-template-columns: 1fr;
}
#header #nav-bar #first-nav {
  background: var(--light-gray);
}

#header #nav-bar #first-nav .container .first-child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#header #nav-bar #first-nav .container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
}
#header #nav-bar .navigation-list {
  display: flex;
}
#header #nav-bar .navigation-list li {
  padding: 2px;
  margin: 0px 7px;
}
.logo {
  width: 100px;
  /* height: 50px; */
}

#header .header-account-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--white-color);
  border: 1px var(--light-gray-2) solid;
  cursor: pointer;
}

#header .header-account-img img {
  width: 30px;
  height: 30px;
}

@media (max-width: 1090px) {
  #header #nav-bar .navigation-list {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  /* Navigation */
  #header #nav-bar .nav-container {
    display: grid;
    grid-template-columns: 1fr;
  }
  #header #nav-bar #first-nav {
    background: var(--light-gray);
  }
  #header #nav-bar #first-nav .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #header #nav-bar #first-nav .container div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo {
    width: 100px;
    /* height: 50px; */
  }
  /* container */
}
