.App {
  position: relative;
}

.App .AppSpinner {
  position: absolute;
  top: 0;
  right: 0;
}

.checkbox-group {
  display: flex;
  flex-direction: row;
  margin: 4px 0px;
}

/*  */
/*  */
/* form for login*/

.form-wrapper {
  max-width: 400px;
  margin: 100px auto;
  background: var(--white-color);
  box-shadow: 0px 14px 80px var(--semi-transparent-dark);
  padding: 2rem 1rem;
  border-radius: 15px;
  transition: all 0.3s;
}

.form-wrapper > * {
  margin: 10px;
  padding: 10px;
}

.form-wrapper #form-group {
  width: 100%;
  margin: auto;
}

.form-wrapper #form-group .phone-input-wrapper {
  border: var(--dark-gray) solid 1px;
  color: var(--light-gray-2);
  padding-left: 5px;
  width: 100%;
  border-radius: 5px;
}
.form-wrapper #form-group .phone-input-wrapper .phone-input {
  border: none;
}
.form-wrapper #form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--light-gray-2);
  border: none;
  border-radius: 5px;
  outline: none;
}

.form-wrapper #form-group button {
  width: 100%;
  margin: 20px 0px;
  border-radius: 5px;
  background-color: var(--color-primary);
}

/* for search */
.search-bar {
  display: flex;
  border: none;
}
.search-bar .search-wrapper input {
  padding-right: 350px;
}
.search-bar .search-wrapper {
  position: relative;
}

.search-container {
  display: flex;
}
.search-bar input {
  border-radius: 5px;
  border: var(--light-color) 1px solid;
  outline: none;
  max-width: 500px;
  padding: 0.6rem 1rem;
}
.search-bar li {
  cursor: pointer;
  border-bottom: #ddd 1px solid;
}

.search-bar input:focus {
  outline: none;
  border-color: var(--color-primary);
}
#user-portal #search .medicine-nav-container .search-bar .search-wrapper input {
  padding-right: 50px;
}
/* AD */

.ad {
  padding: 10px 0.5rem 0;
}
.ad .ad-container {
  padding: 2rem 1rem;
  border-radius: 20px;
}

.ad .ad-container a {
  border-radius: 10px;
}
/* search */
.search {
  padding: 10px;
  border-radius: 5px;
  border: var(--light-color) 1px solid;
  font-size: 15px;
}

.search:focus {
  outline: none;
  border-color: var(--color-primary);
}
/* main-search */
.main-search {
  display: flex;
  align-items: center;
  justify-content: center;
}
.h-3v {
  height: 30vh;
}

/* banner */
.banner-img {
  max-width: 300px;
  margin: auto;
}
.banner-card-img {
  max-width: 100px;
}
/* Acc Sidebar container */

.App .acc-sidebar-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
}
/* ///table */
.container-table {
  max-width: 800px;
  margin: auto;
}
.tableSubCols.heading {
  font-weight: bolder;
}
.tableSubCols.details {
  font-size: smaller;
}
.custom-scrollbar .ant-table-body {
  scrollbar-width: thin !important;
  scrollbar-color: #888 #f1f1f1 !important;
}
/* ///////////////////////////// */

@media (max-width: 770px) {
  body {
    font-size: 14px;
  }

  /* utility */
  .container {
    padding: 0.5rem 0.5rem;
  }
  .card {
    min-width: 150px;
    margin: 5px;
  }
  .img-card {
    max-width: 150px;
  }
  .l-heading {
    font-size: 1.5rem;
  }
  .grid-2-1 {
    grid-template-columns: 1fr;
  }
  .reverse > div:first-child {
    grid-row: 2;
  }

  /*suggests a grid container with 3 or 2 columns for desktop and 1 column for mobile. */

  .container-grid-2-1 {
    grid-template-columns: 1fr;
  }

  .container-grid-3-1 {
    grid-template-columns: 1fr;
  }
  .container-grid-4-1 {
    grid-template-columns: 1fr;
  }
  .container-grid-7-1 {
    gap: 0px;
    display: flex;
    flex-direction: column;
  }
  .container-grid-4-1 {
    grid-template-columns: 1fr;
  }
  .container-grid-1fr-2fr {
    grid-template-columns: 1fr;
  }

  /*  */
  /*  */
  /*  */
  /* patient-form-container */

  .patient-form-container form {
    padding: 20px;
  }
  /* login form container */
  .form-wrapper {
    min-width: 50px;
    padding: 2rem;
  }
  /* ////////////for registration form */
  .sign-up-form {
    min-width: 90%;
    padding: 10px;
  }
  /*  */
  /*  */
  /*  */

  #health-feed-section ul {
    list-style: disc;
    margin-left: 1rem;
  }

  #health-feed-section h1 {
    font-size: 1.5rem;
  }
  /* security */
  .main-search .main-search-container .search {
    min-width: 95vw;
  }
  /* iso-certified */
  #iso-certified .container > *:nth-child(3) {
    grid-column: 1;
  }

  #secure-platform .card,
  #secure-place .card {
    height: auto;
  }

  /* search doctors */
  #header-search-doctor i {
    font-size: 30px;
  }
  /* showcase */

  .video-consult .container {
    padding-bottom: 0;
  }
  #showcase .showcase-container {
    display: block;
  }

  /* Specialties */

  /* common health */

  /* our doctor */

  /* how it works */
  #how-it-works .how-its-work-container {
    display: block;
  }
  #how-it-works .card *:first-child {
    padding-right: 20px;
  }
  /* benefit */

  #benefit .benefit-container {
    grid-template-columns: repeat(2, 1fr);
  }

  /* Consultation */
  #consultation .consultation-container {
    display: block;
    padding: 0.5rem 0;
    border-radius: 8px;
  }

  /* online doctor */

  /* testimonials */
  #testimonials .card {
    min-width: 100%;
    padding: 15px;
  }

  /* faqs */

  /* app download */

  /* home page */

  /* showcase */

  /* Our offers */
  #our-offers .card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .lab-container {
    grid-column: 1 / span 2;
    /* overflow: hidden; */
  }
  .lab-container .card {
    min-width: 100px;
  }

  /* lab */

  /* find top hospital*/
  #find-top-hospital-doctor .card .card-img {
    border-bottom: 1px solid var(--light-color);
    padding: 1.5rem;
  }

  /* affordable-full-body */
  /* affordable-full-body checkups */
  #affordable-full-body-checkups .card-container {
    display: flex;
  }
  #affordable-full-body-checkups .card {
    min-width: 400px;
  }

  /* press article */
  #press-articles i {
    margin: 4px;
    font-size: 30px;
    color: var(--light-color);
  }
  /* search doctors */
  #section-search-doctor i {
    font-size: 30px;
  }
  #section-search-doctor #search-box {
    display: grid;
    grid-template-columns: 1fr 4fr;
  }
  .affordable-footer .flex-row {
    justify-content: space-between;
  }

  #nav-bar #second-nav ul {
    display: flex;
    justify-content: center;
  }
  #nav-bar #second-nav ul li {
    margin-right: 15px;
    min-height: 40px;
    display: flex;
    /* justify-content: end; */
    align-items: center;
  }
  #nav-bar #second-nav ul a {
    display: block;
    padding: 0 5px;
    text-align: center;
    color: var(--dark-color);
  }
  #nav-bar #second-nav ul li:hover {
    border-bottom: 1px solid var(--color-secondary);
  }
  #order-medicine-second-nav .container ul {
    display: grid;
    grid-template-columns: 6fr 1fr 1fr;
  }
  #nav-bar #third-nav ul {
    display: flex;
    justify-content: center;
  }
  #nav-bar #third-nav ul li {
    margin-right: 15px;
    min-height: 40px;
    display: flex;
    /* justify-content: end; */
    align-items: center;
  }
  #nav-bar #third-nav ul a {
    display: block;
    padding: 0 5px;
    text-align: center;
    color: var(--dark-color);
  }
  #nav-bar #third-nav ul li:hover {
    border-bottom: 1px solid var(--color-secondary);
  }
}
@media screen and (max-width: 400px) {
  /* .patient-form-container {
    padding: 5px;
  } */
  .patient-form-container form {
    padding: 5px;
    margin: 5px;
  }
  .sign-up-form {
    width: 90%;
    padding: 5px;
  }
}
