#give-feedback .container {
  max-width: 1100px;
  margin: 20px auto;
  padding: 1px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

#give-feedback .medicine-form-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#give-feedback .medicine-form-container label {
  font-size: large;
  margin-bottom: 20px;
}

#give-feedback .medicine-form-container input {
  width: 100%;
  height: 40px;
  margin-top: 10px;
  font-size: large;
  margin-bottom: 20px;
}

#give-feedback .medicine-form-container form {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px));
  min-width: 90%;
  margin: auto;
  background: var(--white);
  box-shadow: 0px 5px 10px var(--translucent-navy);
  padding: 40px 50px 20px 50px;
  border-radius: 15px;
  transition: all 0.3s;
}

#give-feedback .medicine-form-container form textarea {
  padding: 10px;
  text-align: justify;
  width: 100%;
  height: 100px;
  margin-top: 10px;
  margin-bottom: 20px;
}

#give-feedback .medicine-form-container form h2 {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-decoration: underline;
}

#give-feedback .post-submit button {
  background-color: var(--color-primary);
  font-size: 20px;
}

#give-feedback .add-to-cart-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

#give-feedback .add-to-cart-button button {
  background-color: var(--color-primary-1);
  display: block;
  border-radius: 5px;
  color: var(--light-color);
  padding: 10px 10px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin: 0 20px 20px;
}
#give-feedback .add-to-cart-button:hover {
  opacity: 0.8;
}

#give-feedback #confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

/* .hidden {
  display: none;
} */

#give-feedback .popup-container {
  background-color: var(--light-color);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

#give-feedback .popup-title {
  margin-bottom: 10px;
  font-weight: bold;
}

#give-feedback .popup-message {
  font-size: 16px;
}

#give-feedback .button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

#give-feedback .primary-button {
  background-color: var(--light-blue);
  color: var(--dark-color);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

#give-feedback .secondary-button {
  background-color: var(--light-blue);
  color: var(--dark-color);

  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#give-feedback .primary-button:hover,
#give-feedback .secondary-button:hover {
  opacity: 0.8;
}
@media (max-width: 600px) {
  #give-feedback .container {
    max-width: 450px;
    margin: auto;
    padding: 1px;
    /* overflow: hidden; */
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  #give-feedback .medicine-form-container {
    max-width: 400px;
    /* margin-right: 50px; */
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  #give-feedback .medicine-form-container label {
    font-size: smaller;
    margin-bottom: 20px;
  }

  #give-feedback .medicine-form-container input {
    width: 300px;
    height: 30px;
    margin-top: 5px;
    font-size: medium;
    margin-bottom: 20px;
  }

  #give-feedback .medicine-form-container form textarea {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: justify;
  }

  #give-feedback .post-submit {
    margin: auto;
  }

  #give-feedback .post-submit button {
    background-color: var(--color-primary);
    font-size: 15px;
  }
}
