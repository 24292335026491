#pharmacist-cart .cart-wrapper {
  display: flex;
  justify-content: space-between;
}

#pharmacist-cart .cart-wrapper .available {
  color: red;
}
#pharmacist-cart .cart-wrapper .available-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

#pharmacist-cart .cart-container .underline {
  padding: 2px 80px;
  background: var(--green-color);
  margin: auto;
  border-radius: 50px;
  margin-bottom: 10px;
}
#pharmacist-cart .cart-container {
  max-width: 1100px;
  margin: 50px 200px;
  padding: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 5px var(--dark-gray-1);
}

#pharmacist-cart .cart-container h1 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 10px;
  color: var(--green-color);
}

#pharmacist-cart .cart-container img {
  width: 150px;
  height: 150px;
}

#pharmacist-cart .cart-container .content-wrapper {
  padding: 20px;
  display: flex;
  gap: 10px;
}

@media (max-width: 800px) {
  #pharmacist-cart .cart-container {
    width: 350px;
    margin: 30px auto;
    padding: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 5px var(--dark-gray-1);
  }

  #pharmacist-cart .cart-container h1 {
    font-size: 1.5rem;
    text-align: center;
    /* margin-bottom: 20px; */
    color: var(--green-color);
  }

  #pharmacist-cart .cart-container h2 {
    font-size: 0.85rem;
    /* margin-bottom: 20px; */
  }

  #pharmacist-cart .cart-container h3 {
    font-size: 0.75rem;
    /* margin-bottom: 20px; */
  }

  #pharmacist-cart .cart-container img {
    width: 100px;
    height: 100px;
  }
}
