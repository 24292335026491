.login-select {
  width: 100%;
  height: fit-content;
}

#AdminLogin.form-container {
  margin: auto;
  padding: 2rem;
  margin-top: 3rem;
  max-width: 400px;
  border-radius: 5px;
  border: 1px solid #ddd;
}
