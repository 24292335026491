#material-full-information .materialContainer {
  width: 90%;

  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 2px 2px var(--grayish);
  margin: 3rem auto;
}

#material-full-information .materialContainer h2 {
  padding-top: 30px;
  margin-bottom: 10px;
  text-align: center;
}

#material-full-information .materialContainer .content-wrapper {
  margin: 20px;
  display: flex;
  padding-left: 20px;
  gap: 15px;
}
#material-full-information .materialContainer .right-container img {
  width: 400px;
  height: 200px;
}

#material-full-information .underline {
  padding: 2px 2px 2px 1px;
  width: 300px;
  background: var(--dark-color);
  margin: auto;
  border-radius: 50px;
  margin-bottom: 3rem;
}

@media (max-width: 500px) {
  #material-full-information .materialContainer h2 {
    padding-top: 20px;
    margin-bottom: 10px;
    text-align: center;
  }

  #material-full-information .underline {
    padding: 2px 2px 2px 1px;
    width: 150px;
    background: var(--dark-color);
    margin: auto;
    border-radius: 50px;
    margin-bottom: 1rem;
  }

  #material-full-information .materialContainer .right-container img {
    width: 150px;
    height: 100px;
  }
  #material-full-information .materialContainer .left-container {
    font-size: smaller;
    margin-bottom: 10px;
  }

  #material-full-information .materialContainer .left-container p {
    line-height: 15px;
    padding-bottom: 2px;
  }
  #material-full-information .materialContainer .content-wrapper {
    margin: 10px;
    display: flex;
    padding-left: 0px;
  }
}
