#searchEquipment .equipment-nav-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 10;
  background: var(--light-color);
}

#searchEquipment .equipment-nav-container .search-bar {
  display: flex;
  border: none;
}

#searchEquipment
  .equipment-nav-container
  .search-container
  .search-list-container {
  position: absolute;
  width: 100%;
  border: var(--light-color) 1px solid;
  background: var(--white-color);
}

#searchEquipment .equipment-nav-container .search-bar input {
  border-radius: 5px;
  border: var(--light-color) 1px solid;
  outline: none;
  width: 100%;
  padding-right: 250px;
}
#searchEquipment .equipment-nav-container .search-bar li {
  cursor: pointer;
  padding: 5px 1rem;
  color: var(--color-primary);
  border-bottom: var(--light-color) 1px solid;
}

#searchEquipment .equipment-nav-container .search-bar li:hover {
  background: var(--light-color);
}
#searchEquipment .equipment-nav-container .search-bar input:focus {
  outline: none;
  border-color: var(--color-primary);
}

#diagnosticPortal .clr {
  clear: both;
}

#diagnosticPortal .last-button {
  margin: auto;
  text-align: center;
  align-items: center;
}

#diagnosticPortal .diagnostic-portal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

#diagnosticPortal .diagnostic-portal .diagnostic-title {
  margin-bottom: 30px;
  font-size: 40px;
  text-decoration: underline;
}

#diagnosticPortal .medicine-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  width: 100%;
}

#diagnosticPortal .medicine-grid img {
  height: 200px;
  width: 200px;
}

#diagnosticPortal .medicine-card {
  border: 1px solid var(--grayish-1);
  background: var(--light-gray);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
}

#diagnosticPortal .medicine-card h2 {
  font-weight: 100px;
  margin-bottom: 5px;
}

#diagnosticPortal .medicine-card p {
  margin-bottom: 5px;
}

#diagnosticPortal .post-medicine-button {
  background-color: var(--color-primary);
  color: var(--light-color);
  padding: 15px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  max-width: 300px;
  margin: 20px auto;
  font-size: large;
  text-align: center;
}

#diagnosticPortal .post-medicine-button:hover {
  opacity: 0.8;
}

@media (max-width: 500px) {
  #searchEquipment .equipment-nav-container .search-bar input {
    border-radius: 5px;
    border: var(--light-color) 1px solid;
    outline: none;
    width: 250px;
    padding-right: 0;
    font-size: 10px;
  }
  #searchEquipment .search-list-container p {
    font-size: x-small;
    padding: 0 10px;
  }

  #searchEquipment .equipment-nav-container .search-bar li {
    cursor: pointer;
    padding: 4px 1rem;
    font-size: 10px;
    color: var(--color-primary);
    border-bottom: var(--light-color) 1px solid;
  }
  #diagnosticPortal .diagnostic-portal {
    padding: 30px;
  }
  #diagnosticPortal .diagnostic-portal .diagnostic-title {
    font-size: 25px;
    text-align: center;
  }

  #diagnosticPortal .medicine-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
  #diagnosticPortal .medicine-grid img {
    height: 100px;
    width: 100px;
  }
  #diagnosticPortal .medicine-card p {
    margin-bottom: 0;
  }
  #diagnosticPortal .medicine-card h2 {
    font-size: medium;
  }
  #diagnosticPortal .post-medicine-button {
    width: 200px;
    font-size: small;
    padding: 5px;
    margin-bottom: 10px;
    text-align: center;
  }
}
