#female-body {
  position: relative;
  width: 100%;
}

#female-body .female-front-body-container-card {
  position: relative;
  height: 550px;
  width: 250px;

  overflow: hidden;
}
#female-body .female-front-hoverable-svg-container {
  position: absolute;
  top: -13px;
  left: 25px;
  width: 500px;
  height: 590px;
}
#female-body .female-front-body-img {
  width: 210px !important ;
  position: absolute;
  top: 15px;
  left: 25px;
  height: 490px;
}

#female-body .female-back-body-container-card {
  position: relative;
  height: 550px;
  width: 250px;
  overflow: hidden;
}
#female-body .female-back-hoverable-svg-container {
  position: absolute;
  top: -25px;
  left: 25px;
  width: 515px;
  height: 630px;
}
#female-body .female-back-body-img {
  width: 210px !important ;
  position: absolute;
  top: 15px;
  left: 25px;
  height: 490px;
}

#female-body .skin {
  position: absolute;
  top: 200px;
  right: 180px;
  z-index: 1;
  cursor: pointer;
  width: 40px;
}
#female-body .rotation {
  position: absolute;
  top: 180px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
  width: 40px;
}
#female-body .rotation:hover {
  border-radius: 5px;
  /* background: #f3f3f3; */
}
#female-body .hidden {
  display: none;
}
#female-body .hoverable {
  fill: #5899c1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

#female-body .hoverable:hover {
  opacity: 0.7;
}
