#user-portal .medicine-nav-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 10;
  background: var(--light-color);
}

#user-portal .medicine-nav-container .search-bar {
  display: flex;
  border: none;
}
#user-portal .medicine-nav-container .search-bar .search-wrapper input {
  padding-right: 350px;
}
#user-portal .medicine-nav-container .search-bar .search-wrapper {
  position: relative;
}
#user-portal .search-list-container .lists .med-list {
  color: var(--color-primary);
}

#user-portal .medicine-nav-container .search-container {
  display: flex;
}
#user-portal .medicine-nav-container .search-container .search-list-container {
  position: absolute;
  width: 100%;
  border: var(--light-color) 1px solid;
  background: var(--white-color);
}

#user-portal .medicine-nav-container .search-bar input {
  border-radius: 5px;
  border: var(--light-color) 1px solid;
  outline: none;
  max-width: 500px;
  padding: 0.6rem 1rem;
}
#user-portal .medicine-nav-container .search-bar li {
  padding: 5px 1rem;
  cursor: pointer;
  border-bottom: var(--light-color) 1px solid;
}
#user-portal .medicine-nav-container .search-bar li:hover {
  background: var(--light-color);
}

#user-portal .medicine-nav-container .search-bar input:focus {
  outline: none;
  border-color: var(--color-primary);
}

#user-portal #choose_file {
  /* border: 1px solid #ccc; Light border */
  margin: 10px;

  padding: 5px 2px;
  font-size: 12px;
  cursor: pointer;
}

#user-portal #choose_file .inputs::file-selector-button {
  display: none;
  background-color: var(--accent-blue-1);
  padding: 10px 5px;
}

#user-portal .btn-upload {
  background-color: var(--light-blue);
  padding: 10px;
  border-radius: 10px;
}

#user-portal #cart-btn {
  background-color: rgb(38, 48, 135);
  padding: 3px 10px;
  text-align: center;

  border-radius: 10px;
  float: right;
  font-size: 15px;
  color: var(--light-color);
}
#user-portal .medicine-detail img {
  max-width: 300px;
  max-height: 250px;
}

#user-portal .medicine-detail {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 25px;
  margin-bottom: 50px;
}

#user-portal .img-slider-section {
  width: 100%;
}
#user-portal .slider-card {
  width: 100%;
  height: 280px;
  margin: 10px auto;
  position: relative;
  overflow: hidden;
  border: 1px var(--grayish) solid;
  background-color: var(--background-color);
}

#user-portal .container .img-slider img {
  height: 100%;
  background-color: var(--grayish);
  object-fit: cover;
  z-index: -1;
}
#user-portal .slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: transform 1s;
}
#user-portal .content-slide {
  flex-direction: column;
}

#user-portal .imageSection {
  width: 100%;
  margin: 80px auto; /* Center the slider horizontally */
}

#user-portal .sectionCenter {
  position: relative; /*Needed for button positioning */
}

#user-portal .sectionCenter .imagePart1 {
  position: relative;
}

#user-portal .right-functionalities {
  display: flex;
  gap: 5px;
}

#user-portal .right-functionalities .notification {
  font-size: 25px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border: none;
  color: var(--color-primary);
}

#user-portal .sectionCenter button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
#user-portal .sectionCenter .imagePart img {
  height: 300px;
  object-fit: cover;
}

#user-portal .user-cart-btn {
  margin: auto;
}
/* StyledButton.css */
#user-portal .prev,
#user-portal .next {
  background-color: var(--grayish); /* Semi-transparent background */
  color: var(--light-color);
  padding: 10px;
  border: 50px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 30px;
}

#user-portal .prev {
  left: 10px; /* Adjust button position */
}

#user-portal .next {
  right: 10px; /* Adjust button position */
}
@media (max-width: 800px) {
  #user-portal #choose_file .inputs::file-selector-button {
    display: none;
    background-color: var(--accent-blue-1);
    padding: 5px 1px;
  }

  #user-portal .btn-upload {
    background-color: var(--light-blue);
    padding: 0px;
    border-radius: 10px;
  }

  #user-portal .medicine-nav-container .search-list-container p {
    font-size: x-small;
    padding: 0 10px;
  }
  #user-portal .medicine-nav-container .search-bar li {
    padding: 5px 1rem;
    cursor: pointer;
    border-bottom: var(--light-color) 1px solid;
    font-size: x-small;
  }
  #user-portal .medicine-nav-container .search-bar input {
    border-radius: 5px;
    border: var(--light-color) 1px solid;
    outline: none;
    max-width: 500px;
    padding: 0.3rem 0.5rem;
    font-size: x-small;
  }
  #user-portal .medicine-nav-container .search-bar .search-wrapper input {
    padding-right: 60px;
  }
  #user-portal #cart-btn {
    background-color: rgb(38, 48, 135);
    padding: 5px 8px;
    text-align: center;
    border: 1px solid var(--green-color);
    border-radius: 20px;
    float: right;
    font-size: 8px;
  }
  #user-portal .right-functionalities .notification {
    font-size: 20px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border: none;
    color: var(--color-primary);
  }

  #user-portal .medicine-nav-container .search-container {
    display: flex;
    flex-direction: column;
  }

  #user-portal #choose_file {
    /* border: 1px solid #ccc; Light border */
    margin-top: 1px;
    margin-right: 5px;
    padding: 2px 2px;
    font-size: 10px;
    cursor: pointer;
  }
  #user-portal .btn-upload {
    background-color: var(--light-blue);
    padding: 5px;
    border-radius: 10px;
  }
}
