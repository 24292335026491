#equipmentInfo .materialContainer {
  max-width: 1100px;
  align-items: center;
  margin: auto;
}

#equipmentInfo .materialContainer .right-container img {
  margin-top: 15px;
}
#equipmentInfo .underline {
  width: 200px;
  margin-top: 1px;
}

#equipmentInfo .button-container-mat {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 20px;
}

#equipmentInfo .button-container-mat a {
  background-color: var(--color-primary);
  display: block;
  padding: 10px;
  text-align: center;
  max-width: 250px;
  min-width: 100px;
}

#equipmentInfo .button-container-mat a:hover {
  background-color: var(--accent-blue-1);
}

#equipmentInfo h2 {
  align-items: center;
  text-align: center;
  margin-top: 50px;
  font-size: 30px;
  margin-bottom: 35px;

  text-decoration: underline;
}

#equipmentInfo #content-wrapper {
  padding: 0.3rem;
  margin: auto 20px;
  display: flex;
  gap: 10px;
  flex-direction: row;
  text-align: justify;
  justify-content: center;

  flex-wrap: wrap;
}

#equipmentInfo #content-wrapper .left-container {
  width: 60%;
}

#equipmentInfo #content-wrapper .right-container {
  max-width: 35%;
}

#equipmentInfo #content-wrapper .right-container img {
  object-fit: cover;
  height: 200px;
  width: 100%;
}

#equipmentInfo .popup .add-to-cart-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

#equipmentInfo .popup .add-to-cart-button button {
  background-color: var(--color-primary-1);
  display: block;
  border-radius: 5px;
  color: var(--light-color);
  padding: 10px 10px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin: 0 20px 20px;
}
#equipmentInfo .add-to-cart-button:hover {
  opacity: 0.8;
}

#equipmentInfo #confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

/* .hidden {
  display: none;
} */

#equipmentInfo .popup-container {
  background-color: var(--light-color);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

#equipmentInfo .popup-title {
  margin-bottom: 10px;
  font-weight: bold;
}

#equipmentInfo .popup-message {
  font-size: 16px;
}

#equipmentInfo .button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

#equipmentInfo .primary-button {
  background-color: var(--light-blue);
  color: var(--dark-color);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

#equipmentInfo .secondary-button {
  background-color: var(--light-blue);
  color: var(--dark-color);

  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#equipmentInfo .primary-button:hover,
#equipmentInfo .secondary-button:hover {
  opacity: 0.8;
}

@media (max-width: 800px) {
  #equipmentInfo #content-wrapper {
    display: flex;
    flex-direction: column;
  }
  #equipmentInfo #content-wrapper .left-container {
    padding-top: 1rem;
    width: 100%;
  }

  #equipmentInfo #content-wrapper .right-container {
    max-width: 100%;
  }
  #equipmentInfo #content-wrapper .right-container img {
    object-fit: cover;
    height: 200px;
    width: 300px;
    display: block;
    margin: auto;
  }
  #equipmentInfo .add-to-cart-button {
    background-color: var(--light-green); /* Green */
    color: var(--light-color);
    padding: 15px 25px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
    margin: auto;
  }

  #equipmentInfo .popup {
    margin: auto;
  }
}
