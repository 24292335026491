#male-body {
  position: relative;
  width: 100%;
}

#male-body .male-front-body-container-card {
  position: relative;
  height: 500px;
  width: 250px;
  overflow: hidden;
}
#male-body .male-front-hoverable-svg-container {
  position: absolute;
  top: -35px;
  right: -130px;
  width: 510px;
  height: 540px;
}
#male-body .male-front-body-img {
  width: 320px !important ;
  position: absolute;
  top: 10px;
  left: 0;
}

#male-body .male-back-body-container-card {
  position: relative;
  width: 250px;
  height: 500px;
  overflow: hidden;
}
#male-body .male-back-hoverable-svg-container {
  position: absolute;
  top: 25 px;
  left: -109px;
  width: 480px;
  height: 475px;
}

#male-body .male-back-body-img {
  width: 300px !important ;
  position: absolute;
  top: 25px;
  left: 15px;
}
#male-body .skin {
  position: absolute;
  top: 190px;
  right: 5px;
  z-index: 1;
  cursor: pointer;
  width: 50px;
}
#male-body .rotation {
  position: absolute;
  top: 160px;
  right: 5px;
  z-index: 1;
  cursor: pointer;
  width: 40px;
}
#male-body .rotation:hover {
  border-radius: 5px;
  /* background: #f3f3f3; */
}
#male-body .hidden {
  display: none;
}
#male-body .hoverable {
  fill: #5899c1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

#male-body .hoverable:hover {
  opacity: 0.7;
}
