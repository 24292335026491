@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
:root {
  --color-primary: #02959c;
  --color-primary-1: rgb(38, 48, 135);
  --color-secondary: #f3282e;
  --color-primary: #02959c;
  --background-color: #fff;
  --white-color: #ffffff;
  --light-color: #f4f4f4;
  --light-gray-3: #f9f9f9;
  --light-gray: #f7f7f7;
  --light-gray-2: #ccc;
  --grayish-2: #cfcaca;
  --grayish-1: #bbb4b4;
  --grayish: #e0dfdf;
  --dark-gray-1: #ddd8d8;
  --dark-gray: #444;
  --dark-color: #333;
  --video-showcase-bg: #f8e9e6;
  --pale-blue-grey: #e5eef6;
  --icon-bg: #c4cfe4;
  --light-blue: #94b4d6;
  --accent-blue-1: #7ee3e8;
  --accent-blue-2: #2563eb;
  --accent-blue-3: #007bff;
  --accent-blue: #02959c;
  --blue-color: blue;
  --purple-color: #643189;
  --pink-color: #b6499b;
  --bright-pink: #eb14ef;
  --golden-yellow: #e2b134;
  --green-color: green;
  --semi-transparent-black: rgba(0, 0, 0, 0.5);
  --semi-transparent-black-2: rgba(0, 0, 0, 0.3);
  --semi-transparent-dark: rgba(34, 35, 58, 0.2);
  --translucent-navy: rgba(33, 34, 56, 0.2);
  --max-width: 1200px;
  /*  for slider */
  --clr-grey-5: hsl(200, 1%, 51%);
  --spacing: 0.1rem;
  --clr-primary-5: hsl(21, 62%, 45%);
  --transition: all 0.3s linear;
  --shadow-color: rgba(100, 100, 111, 0.2);
  --shadow-blur: 0px 7px 29px 0px;
}

/* css reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}
body {
  font-family: "Lato", sans-serif;
  line-height: 1.5;
  background: var(--background-color);
}
a {
  color: var(--dark-color);
  text-decoration: none;
}
ul {
  list-style: none;
}
p {
  margin: 0.5rem 0;
  line-height: 1.7;
}
img {
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
}
i {
  color: var(--color-secondary);
}
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  background-color: var(--background-color);
  border: 1px solid var(--light-gray-2);
  margin-right: 10px;
}

input[type="checkbox"]:checked {
  background-color: var(--accent-blue-3);
}
input[type="radio"] {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--background-color);
  border: 1px solid var(--light-gray-2);
  margin-right: 10px;
}

input[type="radio"]:checked {
  background-color: var(--accent-blue-3);
}

label {
  cursor: pointer;
}
/* Utility */
.container {
  max-width: var(--max-width);
  margin: auto;
  padding: 1rem 2rem;
  overflow: hidden;
}
.heading {
  height: 30px;
  margin: 0px 0px;
  margin-bottom: 10px;
  /* font-size: 14px; */
  color: var(--dark-color);
  text-align: left;
}
/* card */

.card-container + a {
  display: block;
  width: 100%;
  color: var(--dark-color);
  text-align: center;
  margin-top: 30px;
  padding: 0.3rem;
  border: 1px solid var(--dark-color);
  border-radius: 5px;
}
.box-shadow {
  box-shadow: 5px 5px 5px var(--light-color), -5px -5px 5px var(--light-color);
}

.flex-row {
  flex-direction: row;
}

.btn {
  cursor: pointer;
  display: inline-block;
  color: var(--light-color);
  background: var(--color-secondary);
  padding: 0.5rem 1.5rem;
  text-align: center;
  border: none !important;
}
.btn-outline {
  display: block;
  padding: 0.5rem;
  text-align: center;
  color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
}

/* .f-width {
  width: 100%;
} */

.txt-center {
  text-align: center;
}
.txt-justify {
  text-align: justify;
}

.l-heading {
  font-size: 3rem;
  font-weight: 500;
}
.hidden {
  display: none;
}
.l-text {
  font-size: 20px;
}
.justify-text {
  text-align: justify;
}
.text-primary {
  color: var(--color-primary);
  text-align: center;
}
.text-secondary {
  color: var(--color-secondary);
}
.text-green {
  color: var(--green-color);
}
.text-light {
  color: var(--light-color);
}
.text-golden {
  color: var(--golden-yellow);
}
.btn-primary,
.bg-primary {
  background: var(--color-primary);
  color: var(--light-color);
}
.bg-dark,
.btn-dark {
  background: var(--dark-color);
  color: var(--light-color);
}
.bg-light,
.btn-light {
  background: var(--light-color);
  color: var(--dark-color);
}
.bg-white {
  background: var(--background-color);
}
.bg-icon {
  background: var(--icon-bg);
}
.bg-purple {
  background: var(--purple-color);
  color: var(--light-color);
}
.btn-pink {
  background: var(--pink-color);
}
.bg-blue {
  background-color: var(--accent-blue);
  color: var(--light-color);
}

.bg-dark-blue {
  background-color: var(--color-primary);
  color: var(--light-color);
}
.bg-green {
  background-color: var(--green-color);
  color: var(--light-color);
}
.bg-pink {
  background-color: var(--bright-pink);
  color: var(--light-color);
}
.bg-gray {
  background-color: var(--grayish);
  /* color: var(--light-color); */
}
.bg-dark-gray {
  background-color: var(--dark-gray);
  color: var(--light-color);
}
.mini-box {
  padding: 2px;
  margin: 2px;
  border-radius: 5px;
}
.border-none {
  border: none;
}
.border-bottom {
  border-bottom: 1px solid var(--grayish-1);
  padding: 5px;
}

.hidden {
  display: none;
}

/* new utility */
.card-container {
  display: flex;
  overflow-x: scroll;
  padding: 5px;
}
/* width */
.card-container::-webkit-scrollbar {
  width: 10px;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 150px;
  margin: 5px;
}
.card-container .heading {
  margin: auto;
  text-align: center;
}
.card-container .price {
  font-size: 1rem;
  color: #8f8f9c;
  margin-bottom: 12px;
  font-family: Arial, sans-serif;
  text-align: center;
}
.circle-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  border-radius: 50%;
  /* margin: auto; */
}
.circle-img img {
  width: 100px;
  border-radius: 50% !important;
  aspect-ratio: 1/1;
}

.card-img {
  max-width: 150px;
  margin-bottom: 10px;
}
.card-img img {
  object-fit: cover;
}
.app-img {
  max-width: 400px;
}
.card-content {
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.mid-width {
  min-width: 300px !important;
}
.full-width {
  min-width: 100%;
}

.f-width {
  min-width: 95vw;
}

.border {
  border: 1px solid var(--light-color);
}

.align-start {
  align-items: start;
}
.blur {
  opacity: 0.5;
}

/* padding */

.p-1 {
  padding: 1rem;
}
.py-1 {
  padding: 10px 0;
}
.py-2 {
  padding: 20px 0;
}
.py-3 {
  padding: 30px 0;
}
.py-4 {
  padding: 40px 4px;
}
.px-1 {
  padding: 0 10px;
}
.px-2 {
  padding: 0 20px;
}
.px-3 {
  padding: 0 30px;
}

/* margin */
.m-1 {
  margin: 15px 5px;
}
.m-2 {
  margin: 20px 5px;
}
.m-3 {
  margin: 25px 5px;
}
/* vertical / x-axis*/
.mx-1 {
  margin: 1px 2px;
}
.mx-2 {
  margin: 1px 3px;
}
/* border-bottom */
.border-b-golden {
  border-bottom: 5px solid var(--golden-yellow);
}

/*  */
/* not utility  */
/*  */

/* for 2 columns grid*/

.grid-2-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

/*suggests a grid container with 3 or 2 columns for desktop and 1 column for mobile. */
.container-grid-2-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.container-grid-3-1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}
.container-grid-4-1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.container-grid-7-1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
}
.container-grid-1fr-2fr {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
