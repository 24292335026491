#payment .payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 80px;
  box-shadow: 0 2px 5px 2px var(--grayish);
}

#payment .payment-methods {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

#payment .payment-method {
  background-color: var(--light-color);
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.2s ease-in-out;
}

#payment .payment-method.selected {
  background-color: #ddd;
}

#payment .payment-method img {
  width: 2rem;
  height: 2rem;
}

#payment .payment-details {
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid var(--grayish);
  border-radius: 4px;
}

#payment .disabled-link {
  pointer-events: none; /* Disables pointer interaction */
  opacity: 0.5; /* Makes the link appear grayed out */
  cursor: not-allowed; /* Changes cursor to indicate it's not clickable */
}

#payment #pay-link {
  background-color: var(--color-primary);
  padding: 8px 20px;
}

#payment #pay-button {
  background-color: var(--color-primary);
  color: var(--light-color);
  border: none;
  padding: 10px;
  font-size: medium;
}

#payment #pay-button:hover {
  background: var(--accent-blue-1);
}

#payment .button-container {
  padding: 0px;
}

#payment #confirm-button {
  background-color: var(--accent-blue-1);
  color: var(--dark-color);
  padding: 10px;
}

#payment #confirm-button:hover {
  background: var(--color-primary);
}

#payment #cancel-button {
  background-color: var(--accent-blue-1);
  padding: 10px;
}

#payment #cancel-button:hover {
  background: var(--color-primary);
}

#payment #confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

/* .hidden {
  display: none;
} */

#payment .popup-container {
  background-color: var(--light-color);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

#payment .popup-title {
  margin-bottom: 10px;
  font-weight: bold;
}

#payment .popup-message {
  font-size: 16px;
}

#payment #payment .button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

#payment .primary-button {
  background-color: var(--light-blue);
  color: var(--dark-color);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

#payment .secondary-button {
  background-color: var(--light-blue);
  color: var(--dark-color);

  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#payment .primary-button:hover,
#payment .secondary-button:hover {
  opacity: 0.8;
}
@media (max-width: 500px) {
  #payment .payment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 15px;
    box-shadow: 0 20px 20px 20px var(--grayish);
  }
  #payment .payment-methods {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  #payment .payment-method {
    background-color: var(--light-color);
    padding: 1rem 1.5rem;
    max-width: 150px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    transition: background-color 0.2s ease-in-out;
  }
}
