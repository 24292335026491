#view-cart .view-cart .cart-container {
  max-width: 1100px;
  margin: 50px 200px;
  padding: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 5px var(--dark-gray-1);
}

#view-cart .cart-container h1 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 10px;
  color: var(--green-color);
}

#view-cart .cart-container img {
  width: 150px;
  height: 150px;
}

#view-cart .cart-container .content-wrapper {
  padding: 15px;
  display: flex;
  gap: 10px;
}

@media (max-width: 600px) {
  #view-cart .view-cart .cart-container {
    width: 380px;
    margin: 25px auto;
    padding: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 5px var(--dark-gray-1);
  }

  #view-cart .cart-container h1 {
    font-size: 1.5rem;
    text-align: center;
    /* margin-bottom: 20px; */
    color: var(--green-color);
  }

  #view-cart .cart-container h2 {
    font-size: 0.85rem;
    /* margin-bottom: 20px; */
  }

  #view-cart .cart-container h3 {
    font-size: 0.75rem;
    /* margin-bottom: 20px; */
  }

  #view-cart .cart-container img {
    width: 100px;
    height: 100px;
  }
}
