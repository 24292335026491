#equipmentPost .equipment-form-container {
  max-width: 1100px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#equipmentPost .equipment-form-container label {
  font-size: large;
  margin-bottom: 20px;
}

#equipmentPost .equipment-form-container input {
  width: 100%;
  height: 40px;
  margin-top: 10px;
  font-size: large;
  margin-bottom: 20px;
}

#equipmentPost .equipment-form-container form {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(100px)); */
  min-width: 100%;
  margin: auto;
  background: var(--white);
  box-shadow: 0px 5px 10px var(--translucent-navy);
  padding: 30px;
  border-radius: 15px;
  transition: all 0.3s;
}

#equipmentPost .equipment-form-container form textarea {
  padding: 10px;
  text-align: justify;
  width: 100%;
  height: 100px;
  margin-top: 10px;
  margin-bottom: 20px;
}

#equipmentPost .equipment-form-container form h2 {
  text-align: center;
  margin-bottom: 20px;
  text-decoration: underline;
}

#equipmentPost .equipment-form-container form p {
  color: var(--dark-color);
}

#equipmentPost .equipment-form-container form h3 {
  font-weight: 300;
}

#equipmentPost .post-submit {
  margin: auto;
}

#equipmentPost .post-submit button {
  background-color: var(--color-primary);
  font-size: 20px;
}

@media (max-width: 500px) {
  #equipmentPost .equipment-form-container {
    /* max-width: 500px; */
    width: 360px;
    margin: auto;

    align-items: center;
    display: flex;
    flex-direction: column;

    padding: 2px;
    justify-content: center;
  }
  #equipmentPost .equipment-form-container form {
    margin: auto;
    background: var(--white);
    box-shadow: 0px 5px 10px var(--translucent-navy);
    padding: 20px;
    border-radius: 15px;
    transition: all 0.3s;
  }

  #equipmentPost .equipment-form-container label {
    font-size: medium;
    margin-bottom: 20px;
  }

  #equipmentPost .equipment-form-container input {
    width: 300px;
    height: 30px;
    margin-top: 5px;
    font-size: medium;
    margin-bottom: 20px;
  }

  #equipmentPost .equipment-form-container form textarea {
    padding: 20px 300px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: justify;
  }

  #equipmentPost .post-submit {
    margin: auto;
  }

  #equipmentPost .post-submit button {
    background-color: var(--color-primary);
    font-size: 15px;
  }
}
