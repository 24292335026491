/* footer */
#footer {
  background: var(--dark-gray);
  color: var(--light-color);
}
#footer .container > *:last-child {
  grid-column: 1 / span 4;
  padding: 0.5rem;
  text-align: center;
  font-size: 1rem;
}
#footer .footer-content img {
  background: var(--light-color);
  border-radius: 10px;
}
#footer .plus span {
  display: none;
}

#footer .footer-content p {
  background-color: var(--dark-color);
  /* margin-top: 2rem; */
}

#footer li {
  text-align: start;
}

@media (max-width: 1090px) {
  #footer {
    background-color: inherit;
  }
  #footer .container ul i,
  #footer h4 {
    padding: 5px;
    /* background: var(--icon-bg); */
  }

  #footer .container > ul .list {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    padding: 10px 5px;
  }
  #footer .footer-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #footer .footer-logo img {
    width: 150px;
  }
  #footer .footer-content {
    text-align: justify;
    background-color: inherit;
    padding: 5px 5px;
  }
  #footer .footer-content p {
    background-color: black;
    padding: 15px 5px;
  }

  #footer li {
    /* display: inline-block; */
    padding: 5px 14px;
    margin: 5px;
    cursor: pointer;
  }
  #footer li ul {
    display: none;
  }
  #footer li ul.active {
    display: block;

    padding: 5px;
    margin: 5px;
  }
  #footer li ul li {
    display: block;

    padding: 4px;
    margin: 4px;
  }
  #footer ul,
  #footer li,
  #footer h4,
  #footer a,
  #footer p,
  #footer span {
    margin: 0px !important;
    padding: 2px !important;
    color: var(--dark-color);
  }

  #footer .plus {
    padding: 10px !important ;
    display: block;
    display: flex;
    flex-direction: row;
    background-color: var(--light-gray);
    justify-content: space-between;
  }

  #footer .plus span {
    display: block;
  }

  #footer .drop-ul-2 {
    padding: 15px;
  }
  .container-grid-7-1 {
    gap: 0px;
    display: flex;
    flex-direction: column;
  }
}
